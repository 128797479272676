import { FC } from "react";
import Categories from "./pages/Categories";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Watches from "./pages/Watches";

export interface Route {
    key: string,
    title: string,
    path: string,
    navItem: boolean,
    component: FC<{}>,
}

export const routes: Array<Route> = [
    {
        key: 'login',
        title: 'Login',
        path: '/login',
        navItem: true,
        component: Login,
    },
    {
        key: 'logout',
        title: 'Logout',
        path: '/logout',
        navItem: true,
        component: Logout,
    },
    {
        key: 'home',
        title: 'Home',
        path: '/',
        navItem: true,
        component: Home,
    },
    {
        key: 'watches',
        title: 'Watches',
        path: '/watches',
        navItem: true,
        component: Watches,
    },
    {
        key: 'categories',
        title: 'Categories',
        path: '/categories',
        navItem: true,
        component: Categories,
    },
]