import { CssBaseline, ThemeProvider } from "@mui/material";
import { theme } from "./theme";
import "./App.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { routes } from "./routes";
import { UserProvider } from "./context/UserProvider";

export const notAuthRedirect = (error: any) => {
  const statusCode = error.response ? error.response.status : null;

  if(statusCode === 401){
      window.location.replace('/login');
  }
}

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <UserProvider>
        <CssBaseline />
        <Router>
            <Routes>
              {routes.map((route) => (
                <Route
                  key={route.key}
                  path={route.path}
                  element={<route.component />}
                />
              ))}
            </Routes>
        </Router>
      </UserProvider>
    </ThemeProvider>
  );
}

export default App;