import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
} from '@mui/material';
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { object, string, any, TypeOf } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import { Category } from './Categories';
import { baseURL } from '../constants';

const api = axios.create({
    baseURL,
    withCredentials: true
});
api.interceptors.response.use((response) => response, (error) => {
    const statusCode = error.response ? error.response.status : null;

    if(statusCode === 401){
        window.location.replace('/login');
    }
});

export const tags = [
    'Chronograph',
    'Classic',
    'Manual Winding',
    'Self Winding',
    'Skeleton',
    'Tourbillon',
];

export const movements = [
    'Automatic',
    'Automatic chrono',
    'Automatic declutchable rotor',
    'Mechanical',
    'Quartz',
]

interface Props {
    onClose: () => void
}

const newUserSchema = object({
    title: string()
        .nonempty('Title is required')
        .max(64, 'Title must be less than 100 characters'),
    description: string()
        .nonempty('Description is required'),
    category: string()
        .nonempty('Category is required'),
    tag: string()
        .nonempty('Tag is required'),
    movement: string()
        .nonempty('Movement is required'),
    reference: string()
        .nonempty('Reference is required'),
    model: string()
        .nonempty('Model is required'),
    year: string()
        .nonempty('Year is required'),
    sex: string()
        .nonempty('Sex is required'),
    material: string()
        .nonempty('Material is required'),
    info: string()
        .nonempty('Material is required'),
    image: any().refine(val => val.length > 0, "File is required")
})

type newUserInput = TypeOf<typeof newUserSchema>;
  
export const Create = (props: Props) => {
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState<Category[]>([]);
    const [category, setCategory] = useState('');
    const [tag, setTag] = useState('');
    const [movement, setMovement] = useState('');

    const handleChange = (event: SelectChangeEvent) => {
        setCategory(event.target.value as string);
    };
  
    const methods = useForm<newUserInput>({
      resolver: zodResolver(newUserSchema),
    });

    const {
        register,
        formState: { errors, isSubmitSuccessful },
        reset,
        handleSubmit
    } = methods;
  
    useEffect(() => {
      if (isSubmitSuccessful) {
        reset();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubmitSuccessful]);

    useEffect(() => {
        getCategories();
    }, []);
  
    const onSubmitHandler: SubmitHandler<newUserInput> = async (values) => {

        setLoading(true);
            
        try {
            const formData = new FormData();
            formData.append('title', values.title);
            formData.append('description', values.description);
            formData.append('category', values.category);
            formData.append('tag', values.tag);
            formData.append('movement', values.movement);
            formData.append('reference', values.reference);
            formData.append('model', values.model);
            formData.append('sex', values.sex);
            formData.append('year', values.year);
            formData.append('material', values.material);
            formData.append('info', values.info);
            formData.append('image', values.image[0], 'test.jpg');
            
            await api.post(`watch`, formData);
            
            props.onClose();
        } catch (error) {
            setLoading(false);
        }finally{
            setLoading(false);
        }
    };

    const getCategories = async () => {
        const result = await api.get(`categories?limit=10&size=0`);
        setCategories(result.data.data || []);
    };
  
    return (
        <Box sx={{
            backgroundColor: 'inheret',
        }}>
            <FormProvider {...methods}>
                <Box sx={{ backgroundColor: 'secondary.main', p: 5 }}>
                    <Typography variant='h4' component='h1' sx={{ mb: '2rem' }}>
                        Create Watch
                    </Typography>
                    <Box
                        component='form'
                        noValidate
                        autoComplete='off'
                        onSubmit={handleSubmit(onSubmitHandler)}
                    >
                        <TextField
                            sx={{ mb: 2 }}
                            label='Title'
                            fullWidth
                            required
                            error={!!errors['title']}
                            helperText={errors['title'] ? errors['title'].message : ''}
                            {...register('title')}
                        />

                        <TextField
                            sx={{ mb: 2 }}
                            label='Description'
                            fullWidth
                            required
                            type={'description'}
                            error={!!errors['description']}
                            helperText={errors['description'] ? errors['description'].message : ''}
                            {...register('description')}
                        />

                        <TextField
                            sx={{ mb: 2, width: {lg: '50%', md: '100%'} }}
                            label='Reference'
                            fullWidth
                            required
                            type={'reference'}
                            error={!!errors['reference']}
                            helperText={errors['reference'] ? errors['reference'].message : ''}
                            {...register('reference')}
                        />
                        <TextField
                            sx={{ mb: 2, width: {lg: '50%', md: '100%'} }}
                            label='Model'
                            fullWidth
                            required
                            type={'model'}
                            error={!!errors['model']}
                            helperText={errors['model'] ? errors['model'].message : ''}
                            {...register('model')}
                        />
                        <TextField
                            sx={{ mb: 2, width: {lg: '50%', md: '100%'} }}
                            label='Year'
                            fullWidth
                            required
                            type={'year'}
                            error={!!errors['year']}
                            helperText={errors['year'] ? errors['year'].message : ''}
                            {...register('year')}
                        />

                        <TextField
                            sx={{ mb: 2, width: {lg: '50%', md: '100%'} }}
                            label='sex'
                            fullWidth
                            required
                            type={'sex'}
                            error={!!errors['sex']}
                            helperText={errors['sex'] ? errors['sex'].message : ''}
                            {...register('sex')}
                        />

                        <TextField
                            sx={{ mb: 2, width: {lg: '50%', md: '100%'} }}
                            label='material'
                            fullWidth
                            required
                            type={'material'}
                            error={!!errors['material']}
                            helperText={errors['material'] ? errors['material'].message : ''}
                            {...register('material')}
                        />

                        

                        <FormControl fullWidth sx={{ mb: 2, width: {lg: '50%', md: '100%'} }}>
                            <InputLabel id="category-select-label">Category</InputLabel>
                            <Select
                                labelId="category-select-label"
                                id="category-select"
                                value={category}
                                label="Category"
                                {...register('category')}
                                onChange={handleChange}
                                error={!!errors['category']}
                            >
                                {categories.map(category => (
                                    <MenuItem value={category._id}>{category.title}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>


                        <FormControl fullWidth sx={{ mb: 2, width: {lg: '50%', md: '100%'} }}>
                            <InputLabel id="tag-select-label">Tag</InputLabel>
                            <Select
                                labelId="tag-select-label"
                                id="tag-select"
                                value={tag}
                                label="Tag"
                                {...register('tag')}
                                onChange={(e) => setTag(e.target.value as string)}
                                error={!!errors['tag']}
                            >
                                {tags.map(tag => (
                                    <MenuItem value={tag}>{tag}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth sx={{ mb: 2, width: {lg: '50%', md: '100%'} }}>
                            <InputLabel id="movement-select-label">Movement</InputLabel>
                            <Select
                                labelId="movement-select-label"
                                id="movement-select"
                                value={movement}
                                label="Movement"
                                {...register('movement')}
                                onChange={(e) => setMovement(e.target.value as string)}
                                error={!!errors['category']}
                            >
                                {movements.map(movement => (
                                    <MenuItem value={movement}>{movement}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <TextField
                            sx={{ mb: 2 }}
                            label='Info'
                            fullWidth
                            required
                            type={'description'}
                            error={!!errors['description']}
                            helperText={errors['description'] ? errors['description'].message : ''}
                            {...register('description')}
                            multiline
                            rows={2}
                            maxRows={4}
                        />

                        <input
                            style={{ display: "none" }}
                            id="image"
                            type="file"
                            {...register('image')}
                        />
                        <label htmlFor="image">
                            <Button variant="contained" color="primary" component="span" style={{ marginTop: 15 }}>
                                Upload
                            </Button>
                        </label>
                
                        <LoadingButton
                            variant='contained'
                            fullWidth
                            type='submit'
                            loading={loading}
                            sx={{ py: '0.8rem', mt: '1rem' }}
                        >
                            Create
                        </LoadingButton>
                    </Box>
                </Box>
            </FormProvider>
        </Box>
    );
  };
  
  export default Create;
  