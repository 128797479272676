import React, { FC } from "react";
import { Box } from "@mui/material";
import './Home.css';
import Watches from "./Watches";

const Home: FC<any> = (): JSX.Element => {

    return (
        <Box sx={{
            flexGrow: 1,
            background: '#474538',
            position: 'relative',
        }}>
            
            <Watches />

        </Box>
    );
};

export default Home;