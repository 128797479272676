import { ThemeProvider, createTheme } from '@mui/material/styles';


export const theme = createTheme({
  palette: {
    primary: {
      light: "#63b8ff",
      main: "#263238",
      dark: "#005db0",
      contrastText: "#fff",
    },
    secondary: {
      main: "#fff",
      light: "#82e9de",
      dark: "#00867d",
      contrastText: "#000",
    },
  },
  typography: {
   "fontFamily": "Domaine Text",
   "fontSize": 14,
   "fontWeightLight": 300,
   "fontWeightRegular": 400,
   "fontWeightMedium": 500
  },
  components:{
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily: 'Domaine Text',
          fontSize: 18,
          fontWeight: 400,
        }
      }
    },
    MuiListItem:{
      styleOverrides:{
        root:{
          // textAlign: 'right'
          padding: 0
        }
      }
    },
    MuiAccordionDetails:{
      styleOverrides:{
        root:{
          padding: 0,
        }
      }
    },
    MuiAccordionSummary:{
      styleOverrides:{
        root:{
          padding: 0,
        }
      }
    },
    MuiAccordion:{
      styleOverrides:{
        root:{
          border: '1px solid #e2e2e2',
          borderWidth: '1px 0 0 0',
          boxShadow: 'none',
        }
      }
    }
  }
});