import {
    Box,
    Button,
    SelectChangeEvent,
    TextField,
    Typography,
} from '@mui/material';
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { object, string, any, TypeOf } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import { baseURL } from '../constants';

const api = axios.create({
    baseURL,
    withCredentials: true
});
api.interceptors.response.use((response) => response, (error) => {
    const statusCode = error.response ? error.response.status : null;

    if(statusCode === 401){
        window.location.replace('/login');
    }
});

interface Props {
    onClose: () => void
}

const newCategorySchema = object({
    title: string()
        .nonempty('Title is required')
        .max(32, 'Title must be less than 100 characters'),
    image: any().refine(val => val.length > 0, "File is required")
})

type newCategoryInput = TypeOf<typeof newCategorySchema>;
  
export const CreateCategory = (props: Props) => {
    const [loading, setLoading] = useState(false);
  
    const methods = useForm<newCategoryInput>({
      resolver: zodResolver(newCategorySchema),
    });

    const {
        register,
        formState: { errors, isSubmitSuccessful },
        reset,
        handleSubmit
    } = methods;
  
    useEffect(() => {
      if (isSubmitSuccessful) {
        reset();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubmitSuccessful]);
  
    const onSubmitHandler: SubmitHandler<newCategoryInput> = async (values) => {

        setLoading(true);
        
        try {
            const formData = new FormData();
            formData.append('title', values.title);
            formData.append('image', values.image[0], 'test.jpg');

            await api.post(`category`, formData);
            props.onClose();
        } catch (error) {
            setLoading(false);
        }finally{
            setLoading(false);
        }
    };
  
    return (
        <Box sx={{
            backgroundColor: 'inheret',
        }}>
            <FormProvider {...methods}>
                <Box sx={{ backgroundColor: 'secondary.main', p: 5 }}>
                    <Typography variant='h4' component='h1' sx={{ mb: '2rem' }}>
                        Create Category	
                    </Typography>
                    <Box
                        component='form'
                        noValidate
                        autoComplete='off'
                        onSubmit={handleSubmit(onSubmitHandler)}
                    >
                        <TextField
                            sx={{ mb: 2 }}
                            label='Title'
                            fullWidth
                            required
                            error={!!errors['title']}
                            helperText={errors['title'] ? errors['title'].message : ''}
                            {...register('title')}
                        />

                        <input
                            style={{ display: "none" }}
                            id="image"
                            type="file"
                            {...register('image')}
                        />
                        <label htmlFor="image">
                            <Button variant="contained" color="primary" component="span" style={{ marginTop: 15 }}>
                                Upload
                            </Button>
                        </label>
                
                        <LoadingButton
                            variant='contained'
                            fullWidth
                            type='submit'
                            loading={loading}
                            sx={{ py: '0.8rem', mt: '1rem' }}
                        >
                            Create
                        </LoadingButton>
                    </Box>
                </Box>
            </FormProvider>
        </Box>
    );
  };
  
  export default CreateCategory;
  