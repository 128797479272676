import {
    Box, Button, Modal, Tooltip,
} from '@mui/material';
import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TablePagination } from '@mui/material';
import axios from 'axios';
import CreateCategory from './CreateCategory';
import UpdateCategory from './UpdateCategory';
import { baseURL, UPLOAD_URL } from '../constants';
import { useNavigate } from 'react-router-dom';
import CreateIcon from '@mui/icons-material/Create';
import WatchIcon from '@mui/icons-material/Watch';
import LogoutIcon from '@mui/icons-material/Logout';

const api = axios.create({
    baseURL,
    withCredentials: true
});
api.interceptors.response.use((response) => response, (error) => {
    const statusCode = error.response ? error.response.status : null;

    if(statusCode === 401){
        window.location.replace('/login');
    }
});

export interface Category{
    _id: string,
    title: string,
    createdAt: string,
    image: string,
}
  
export const Categories = () => {
    const [categories, setCategories] = useState<Category[]>([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [total, setTotal] = useState(0);
    const [openCreate, setOpenCreate] = useState(false);
    const [openUpdate, setOpenUpdate] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState<Category>();
    const navigate = useNavigate();

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    
    const getCategories = async () => {
        const result = await api.get(`categories?limit=${rowsPerPage}&size=${page}`);
        setCategories(result.data.data || []);
        setTotal(result.data.total || 0);
    };

    const deleteCategory = async (id: string) => {
        try {
            await api.delete(`category?id=${id}`);
            await getCategories();
        } catch (error: any) {
            alert(error.message);
        }
    };

    useEffect(() => {
        getCategories();
    }, [page, rowsPerPage]);
  
    return (
        <Box sx={{
            flexGrow: 1,
            background: '#474538',
            position: 'relative',
            p: 5,
            minHeight: '100vh',
            overflowY: 'auto'
        }}>
            <Box sx={{
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'space-between'
            }}>
                <Box>
                    <Button sx={{m: 1, color: '#000', background: '#FFF'}} onClick={() => setOpenCreate(true)} startIcon={<CreateIcon />}>Create New</Button>
                    <Button sx={{m: 1, color: '#000', background: '#FFF'}} onClick={() => navigate('/')} startIcon={<WatchIcon />}>Watches</Button>
                </Box>
                <Button sx={{m: 1, color: '#000', background: '#FFF'}} onClick={() => navigate('/logout')} startIcon={<LogoutIcon />}>Logout</Button>
            </Box>
            <Box sx={{ backgroundColor: 'secondary.main', p: 5 }}>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            <TableCell>Watch Id</TableCell>
                            <TableCell align="right">Title</TableCell>
                            <TableCell align="right">Image</TableCell>
                            <TableCell align="right">Created At</TableCell>
                            <TableCell align="right">Delete</TableCell>
                            <TableCell align="right">Edit</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {categories.map((category) => (
                            <TableRow
                                key={category._id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {category._id}
                                </TableCell>
                                <TableCell align="right">
                                    <Tooltip title={category.title}><span>{category.title}</span></Tooltip>
                                </TableCell>
                                <TableCell align="right">
                                     <img src={`${UPLOAD_URL}/${category.image}`} width={90} height={90} style={{objectFit: 'contain'}} />
                                </TableCell>
                                <TableCell align="right">
                                    <Tooltip title={category.createdAt}><span>{category.createdAt}</span></Tooltip>
                                </TableCell>
                                <TableCell align="right">
                                    <Button color="error" variant="outlined" onClick={() => {
                                        deleteCategory(category._id);
                                    }}>Delete</Button>
                                </TableCell>
                                <TableCell align="right">
                                    <Button variant="contained" onClick={() => {
                                        setSelectedCategory(category);
                                        setOpenUpdate(true);
                                    }}>
                                        Edit
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <TablePagination
                rowsPerPageOptions={[20, 50, 100]}
                component="div"
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            
            <Modal
                open={openUpdate}
                onClose={() => setOpenUpdate(false)}
                aria-labelledby="update-modal-title"
                aria-describedby="update-modal-description"
                sx={{width: '50%', m: 'auto', overflowY: 'auto'}}
            >
                <UpdateCategory category={selectedCategory!} onClose={async () => {
                    setOpenUpdate(false);
                    await getCategories();
                }}  />
            </Modal>

            <Modal
                open={openCreate}
                onClose={() => setOpenCreate(false)}
                aria-labelledby="create-modal-title"
                aria-describedby="create-modal-description"
                sx={{width: '50%', m: 'auto', overflowY: 'auto'}}
            >
                <CreateCategory onClose={async () => {
                    setOpenCreate(false);
                    await getCategories();
                }} />
            </Modal>
        </Box>
    );
  };
  
  export default Categories;
  