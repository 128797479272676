import {
    Box,
    TextField,
    Typography,
} from '@mui/material';
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { object, string, array, TypeOf } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useContext, useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import {useNavigate } from 'react-router-dom';
import { UserDataContext } from '../context/UserProvider';
import axios from 'axios';
import { baseURL } from '../constants';

const api = axios.create({
    baseURL,
    withCredentials: true
})

const loginSchema = object({
    username: string()
        .nonempty('User id is required')
        .max(32, 'Name must be less than 100 characters'),
    password: string()
        .nonempty('Password is required'),
})
  
type LoginInput = TypeOf<typeof loginSchema>;
  
export const Login = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const {currentUser: user, setCurrentUser} = useContext(UserDataContext);
  
    const methods = useForm<LoginInput>({
      resolver: zodResolver(loginSchema),
    });

    const {
        register,
        formState: { errors, isSubmitSuccessful },
        reset,
        handleSubmit
    } = methods;
  
    useEffect(() => {
      if (isSubmitSuccessful) {
        reset();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubmitSuccessful]);
  
    const onSubmitHandler: SubmitHandler<LoginInput> = async (values) => {

      setLoading(true);
      try {
        const res = await api.post(`/login`, {
            ...values,
        });

        if(!res){
            return;
        }

        setCurrentUser({
            username: res.data,
        })
        navigate('/');
      } catch (error) {
        console.log(error);
      } finally{ 
        setLoading(false);
      }
    };
  
    return (
        <Box sx={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#474538',
            position: 'relative',
            minHeight: '100vh',
        }}>
            <FormProvider {...methods}>
                <Box sx={{ backgroundColor: 'secondary.main', p: 15 }}>
                    <Typography variant='h4' component='h1' sx={{ mb: '2rem' }}>
                        Login
                    </Typography>
                    <Box
                        component='form'
                        noValidate
                        autoComplete='off'
                        onSubmit={handleSubmit(onSubmitHandler)}
                    >
                        <TextField
                            sx={{ mb: 2 }}
                            label='User ID'
                            fullWidth
                            required
                            error={!!errors['username']}
                            helperText={errors['username'] ? errors['username'].message : ''}
                            {...register('username')}
                        />

                        <TextField
                            sx={{ mb: 2 }}
                            label='Password'
                            fullWidth
                            required
                            type={'password'}
                            error={!!errors['password']}
                            helperText={errors['password'] ? errors['password'].message : ''}
                            {...register('password')}
                        />
                
                        <LoadingButton
                            variant='contained'
                            fullWidth
                            type='submit'
                            loading={loading}
                            sx={{ py: '0.8rem', mt: '1rem' }}
                        >
                            Login
                        </LoadingButton>
                    </Box>
                </Box>
            </FormProvider>
        </Box>
    );
  };
  
  export default Login;
  