import { createContext } from 'react';
import {useState} from 'react';

export interface User {
  username: string,
}

export type UserContextValue = {
  currentUser: User | null,
  setCurrentUser: Function
}

export const UserDataContext = createContext<UserContextValue>({
  currentUser: null,
  setCurrentUser: () => {}
});

export const UserProvider = ({children}: {children: any}) => {

    const [currentUser, setCurrentUser] = useState<User | null>(null);

    return (
        <UserDataContext.Provider value={{
          currentUser, 
          setCurrentUser
        }}>
          {children}
        </UserDataContext.Provider>
    )
}