import {
    Box,
} from '@mui/material';
import { useContext, useEffect } from 'react';
import {useNavigate } from 'react-router-dom';
import { UserDataContext } from '../context/UserProvider';
import axios from 'axios';
import { baseURL } from '../constants';

const api = axios.create({
    baseURL,
    withCredentials: true
});
api.interceptors.response.use((response) => response, (error) => {
    const statusCode = error.response ? error.response.status : null;

    if(statusCode === 401){
        window.location.replace('/login');
    }
});

export const Logout = () => {
    const navigate = useNavigate();
    const { setCurrentUser } = useContext(UserDataContext);

    const logout = async () => {
        try {
            await api.get(`/logout`);
            setCurrentUser(null);
            navigate("/login");
        } catch (error: any) {
            console.log(error.message);
        }
    }
  
    useEffect(() => {
        logout();
    }, []);
  
    return (
        <Box sx={{
            flexGrow: 1,
            backgroundColor: 'inheret',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
        </Box>
    );
  };
  
  export default Logout;
  